import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getStudent = async ({ id, typeCourse, schoolClassesIds }) => {
  const getStudents = await httpGet(
    `${ENDPOINT.STUDENT}/${id}/subjects?${typeCourse}&school_classes_ids=${schoolClassesIds}`
  )

  return getStudents
}

export const getStudentCourse = async ({ id }) => {
  const getCourse = await httpGet(`${ENDPOINT.STUDENT}/${id}/courses`)

  return getCourse
}

export const getStudentDetailsFinances = async ({ id }) => {
  const getFinances = await httpGet(
    `${ENDPOINT.STUDENT_FINANCES_REGISTER}${id}${ENDPOINT.STUDENT_FINANCES_OVERDUE}`
  )

  return getFinances
}

export const getStudentActivitiesDetail = async ({
  disciplineId,
  username
}) => {
  const getActivitiesDetail = await httpGet(
    `${ENDPOINT.STUDENT_SUBJECTS}/${disciplineId}/activities/detail?register=${username}`
  )

  return getActivitiesDetail
}
