import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Tabs from 'components/organisms/Tabs'
import { LoadingPage, Snackbar } from 'components/molecules'
import { useSnack } from 'services/hooks'
import { UNITS } from 'services/constants'
import { gradeTabs } from './tabsGrades'

const Grade = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])

  const isLikeNovoTech = [UNITS.NOVOTEC.id, UNITS.MS.id].includes(
    Number(params.unit)
  )
  const showReclassification = ![UNITS.MS.id].includes(Number(params.unit))

  const isNovoTech = +params.unit === UNITS.NOVOTEC.id
  const isProfessor = params.isProfessor === 'true'

  const gradeTabsList = gradeTabs({
    isLikeNovoTech,
    isNovoTech,
    isProfessor,
    showReclassification
  })

  const defaultTab = gradeTabsList[0]
  const [value, setValue] = useState(defaultTab.index)
  const [isLoading, setIsLoading] = useState(false)

  const { snack, setSnack, snackOpen } = useSnack()

  const paramsToTabs = {
    setSnack,
    setIsLoading,
    isNovoTech,
    isProfessor,
    isLikeNovoTech,
    showReclassification
  }

  return (
    <div style={{ marginTop: '24px' }}>
      <LoadingPage open={isLoading} />
      <Tabs
        customValue={[value, setValue]}
        contents={gradeTabsList.map(({ getContent }) =>
          getContent({ ...paramsToTabs })
        )}
        navigations={gradeTabsList.map((gradeTab) => gradeTab.navigation)}
        justifyContent="end"
      />

      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />
    </div>
  )
}

export default Grade
