import { AcademicRecordPDFtypeMG } from 'components/templates/PDF/academicRecord/MG'
import { AcademicRecordPDFtypePR } from 'components/templates/PDF/academicRecord/PR'
import AcademicRecordPDFtypeSP from 'components/templates/PDF/academicRecord/SP'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  deleleDocumentHistory,
  getAcademicReportMGPDF,
  getAcademicReportPRPDF,
  getAcademicReportSPPDF,
  getStudentInfo,
  getStudentInfoHistory
} from 'services/api/admin'
import { UNITS, yearGradeOldList } from 'services/constants'
import { COMPANY } from 'services/constants/company'
import { updateErrorMessage } from 'services/helpers'
import PDF from 'services/helpers/PDF'

const deleteDocumentHistory = async ({
  setSnack,
  setLoading,
  id,
  setIsDeleteDocumentHistory
}) => {
  setSnack('')
  setLoading(true)
  const { data, error, status } = await deleleDocumentHistory(id)

  setLoading(false)
  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfoHistory
    })
  }
  setIsDeleteDocumentHistory(true)

}

const getStudentHistory = async ({
  setSnack,
  setLoading,
  setStudentHistory,
  setNotFoundSearch,
  register
}) => {
  setSnack('')
  setLoading(true)
  const { data, error, status } = await getStudentInfoHistory(register)
  setLoading(false)
  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfoHistory
    })
  }
  setNotFoundSearch(error)
  setStudentHistory(data)
}

const loadStudentInfo = async ({
  setSnack,
  setLoading,
  setStudentInfo,
  register
}) => {
  setSnack('')
  setLoading(true)

  const { data: response, error, status } = await getStudentInfo(register)

  setLoading(false)

  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfo
    })
  }
  setStudentInfo(response)
}

const openAcademicReportPDF = async ({
  register,
  academicReportId,
  companyId,
  unit,
  onError,
  onFinish,
  openURlLegacy,
  withSignature = true
}) => {
  const pdf = PDF()

  const getAcademicReport = () => {
    if (companyId === COMPANY.MG.id) {
      return {
        api: getAcademicReportMGPDF({ academicReportId, register }),
        pdfDefinitions: AcademicRecordPDFtypeMG
      }
    }

    if (unit.includes('(PR)')) {
      return {
        api: getAcademicReportPRPDF({ academicReportId, register }),
        pdfDefinitions: AcademicRecordPDFtypePR
      }
    }

    return {
      api: getAcademicReportSPPDF({
        academicReportId,
        register
      }),
      pdfDefinitions: AcademicRecordPDFtypeSP
    }
  }

  const academicReportMethods = getAcademicReport({
    academicReportId,
    register
  })

  const {
    data: academicReport,
    status,
    error
  } = await academicReportMethods.api

  if (error) {
    return onError?.({ error, status })
  }

  if (
    !academicReport.data.classInfo.sumModule ||
    [UNITS.NOVOTEC.name, UNITS.MS.name].includes(
      academicReport.data.classInfo.unit
    ) ||
    yearGradeOldList.includes(academicReport.data.classInfo.yearGrade)
  ) {
    onFinish()
    return openURlLegacy()
  }

  const docDefinitions = await academicReportMethods.pdfDefinitions({
    academicRecordData: academicReport.data,
    withSignature
  })

  return pdf.open({
    docDefinitions,
    onFinish,
    onError: (pdfError) => onError?.({ error: pdfError, status })
  })
}

export {
  deleteDocumentHistory,
  getStudentHistory,
  loadStudentInfo,
  openAcademicReportPDF
}
