import moment from 'moment'
import differenceInDays from 'date-fns/differenceInDays'
import add from 'date-fns/add'

const zeroWorkload = 0

export const setDateLocale = (locale = 'pt-br') => moment.locale(locale)

export const dateFormat = (dateValue, datePattern) =>
  moment(dateValue).format(datePattern)

export const createDate = (date = moment()) => moment(date)

export const addDay = (date, days) => date.add(days, 'day')

export const parserTimes = (workloadHours, workloadMinutes) => {
  const hours = workloadHours === zeroWorkload ? '00' : workloadHours
  const minutes = workloadMinutes === zeroWorkload ? '00' : workloadMinutes

  return `${hours}h${minutes}min`
}

export const currentYear = () => new Date().getUTCFullYear()

export const currentYearString = new Date().getUTCFullYear().toLocaleString()
export const currentDay = new Date().getUTCDate().toLocaleString()
export const currentMonth = new Date().getUTCMonth() + 1

export const dateToday = `${currentYearString}-${currentMonth}-${currentDay}`

export const timeZone = new Date().getTimezoneOffset() / 60

export const DATE_FORMAT = {
  DD_MM_YYYY: 'DD/MM/YYYY',
  DD_MM_YYYY_HH_MM_SS: 'DD/MM/YYYY HH:mm:ss',
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM_YYYY: 'MM/YYYY',
  YYYY_MM_DD_HIFEN: 'YYYY-MM-DD',
  DD_MM_YYYY_HIFEN: 'DD-MM-YYYY',
  DD: 'DD',
  MM: 'MM'
}

const FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm'
const FORMAT_DATE_ENV = 'YYYY-MM-DD'
const FORMAT_DATE_DEFAULT = 'DD/MM/YYYY'
const FORMAT_DATE_MM_YY = 'YYYY-MM'
const FORMAT_DATE_MM = 'MM'
const FORMAT_DATE_ISO = 'ddd MMM DD YYYY HH:mm:ss [GMT]Z'

export const formatTime = (value) => {
  const digits = value.replace(/[^0-9]/g, '')

  if (digits.length > 2) {
    const hours = digits.slice(0, 2)
    const minutes = digits.slice(2, 4)
    return `${hours}:${minutes}`
  }

  return digits
}

export const formatDateIso = (dateValue) =>
  moment(dateValue, 'DD/MM/YYYY').format(FORMAT_DATE_ISO)

export const formatDateTime = (dateValue) =>
  moment(dateValue).format(FORMAT_DATE_TIME)

export const formatDateEnv = (dateValue) =>
  moment(dateValue).format(FORMAT_DATE_ENV)

export const formatDate = (dateValue) =>
  moment(dateValue).format(FORMAT_DATE_DEFAULT)

export const formatDateMonthYear = (dateValue) =>
  moment(dateValue).format(FORMAT_DATE_MM_YY)

export const formatDateMonthYearBr = (dateValue) =>
  moment(dateValue).format(DATE_FORMAT.MM_YYYY)

export const formatDateOnlyMonth = (dateValue) =>
  moment(dateValue).format(FORMAT_DATE_MM)

export const formatDateOnlyDay = (dateValue) =>
  moment(dateValue).format(DATE_FORMAT.DD)

export const dateDifferenceInDays = (initialDate, endDate) =>
  differenceInDays(endDate, initialDate)

export const formatDateTimePtBr = (dateValue) =>
  dateValue && moment(dateValue).format(DATE_FORMAT.DD_MM_YYYY_HH_MM_SS)

export const dateAddDays = (date, days) => add(date, { days })

export const isAnExpiredDateByDays = (day, date) => {
  const currentDate = new Date()
  const expiredDate = moment(date).add(day, 'days').format()
  return moment(currentDate).format() > expiredDate
}

export const isBrazilianDateFormatValid = (dateString) => {
  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/
  return datePattern.test(dateString)
}

export const validExpiredDate = (day, date) => {
  const currentDate = new Date()
  const expiredDate = moment(date).add(day, 'days').format()
  const isInValid = moment(currentDate).format() > expiredDate
  return isInValid
}

export const isValidYear = (value) => {
  const dateString = value
  const yearRegex = /\d{4}/
  const yearMatch = dateString?.match(yearRegex)

  if (yearMatch && yearMatch.length) {
    const year = parseInt(yearMatch[0], 10)
    const TWO_THOUSAND_YEAR = 2000
    const firstTwoCharFromStr = year.toString().substring(0, 2)

    return (
      year >= TWO_THOUSAND_YEAR && !['00', '19'].includes(firstTwoCharFromStr)
    )
  }

  return false
}

export const getDateStartOfMonth = (date = new Date()) =>
  moment(date).startOf('month')

export const addMonthsToDate = (monthsToAdd, dateValue = new Date()) =>
  moment(dateValue).add(monthsToAdd, 'months')

export const convertDateToUTC = (date, locale = 'en') =>
  new Date(new Date(date).toLocaleDateString(locale, { timeZone: 'UTC' }))

export const dateFromPatternBR = (dateBr) => {
  // brazilian pattern = day/month/year
  const [day, month, year] = dateBr.split('/')
  return new Date(year, month - 1, day)
}
