import { useTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { STUDENT_ROUTE } from 'services/constants/routes/student'
import { useUserStore } from 'store/GlobalContext'
import ComputerIcon from '@mui/icons-material/Computer'

import PaidIcon from '@mui/icons-material/Paid'
import InfoIcon from '@mui/icons-material/Info'
import MessageIcon from '@mui/icons-material/Message'
import InstagramIcon from '@mui/icons-material/Instagram'

import SpellcheckIcon from '@mui/icons-material/Spellcheck'

import Grid from '@mui/material/Unstable_Grid2'
import ButtonFlat from 'components/molecules/ButtonFlat'
import WarningCountDown from 'components/molecules/WarningCountDown'
import { useEffect, useState } from 'react'
import {
  getFinancialNextBill,
  getStudentHasOverdue
} from 'services/api/student'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { Button } from '@mui/material'
import { millisecondDayConversionFactor, UNITS } from 'services/constants'
import { useAnalytics } from 'Context/AnalyticsContext'
import { STUDENT_HUB_EVENTS } from 'events/hub'
import { formatDate, updateErrorMessage } from 'services/helpers'
import { useSnack } from 'services/hooks'
import * as Styled from './style'
import {
  acceptTermReEnrollment,
  reEnrollmentList
} from '../ReEnrollment/components/Content/service'

const StudentHub = () => {
  const navigate = useNavigate()
  const userStore = useUserStore()
  const location = useLocation()
  const names = userStore.name.trim().split(' ')
  const initials = names.map((n) => n.substring(0, 1).toUpperCase())
  const firstName = names[0]
  const { setLoading } = useLoading()
  const analytics = useAnalytics()
  const [nextBill, setNextBill] = useState(null)

  const theme = useTheme()
  const today = new Date()
  const { username: id, unit } = userStore

  const [openModalStudentOverdue, setOpenModalStudentOverdue] = useState(false)
  const [studentOverdue, setStudentOverdue] = useState({})
  const { snack, setSnack, snackOpen, updateSnack } = useSnack()

  const displayGradesAndFinance = !(
    unit === UNITS.NOVOTEC.name ||
    unit === UNITS.MS.name ||
    unit === UNITS.TRAILS_OF_THE_FUTURE.name
  )

  const buttonStyle = {
    backgroundColor: '#FAFAFA',
    borderRadius: '12px',
    padding: '4px 16px 4px 16px',
    gap: '8px',
    textDecoration: 'underline',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '0.80rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem'
    }
  }

  const callbackSuccess = () => {}

  const callHiBot = (studentName, studentPhone, studentCpf) => {
    // eslint-disable-next-line no-undef
    HiBot.start(
      `&nome_usuario=${studentName}&telefone=${studentPhone}&cpf=${studentCpf}`
    )
  }

  const handleAcceptTerm = async ({
    isAutomatic = false,
    studentReenrollmentId = null
  }) => {
    await acceptTermReEnrollment({
      id: studentReenrollmentId ?? userStore.student_reenrollment_id,
      callbackSuccess,
      isAutomatic
    })
  }

  const warningNextBillFiveToOne = (deadline, deadlineDays) => {
    const header = `Lembrete: falta(m) ${deadlineDays} dia(s)`
    const colorWarning = theme.palette.hub.lightGreen
    const displayClock = false
    const bills = ' Pagar agora'
    const displaySeconds = false
    const onClick = () => {
      analytics.logEvent(STUDENT_HUB_EVENTS.banner.btn.openGetnet)
      window.open(nextBill.credit_card, '_blank')
    }
    const message = `Estamos aqui para lembrar que sua mensalidade vence em ${deadlineDays} dias. 
      Se precisar de ajuda com o pagamento ou qualquer outra dúvida, conte com a gente! 😊`

    const footer = (
      <Button
        onClick={onClick}
        sx={{
          color: colorWarning,
          ...buttonStyle,
          '&.MuiButton-root:hover': { bgcolor: 'white' }
        }}
      >
        {' '}
        {bills}
      </Button>
    )
    return (
      <WarningCountDown
        backgroundColor={colorWarning}
        header={header}
        deadline={deadline}
        displayClock={displayClock}
        message={message}
        footer={footer}
        displaySeconds={displaySeconds}
        height="150px"
      />
    )
  }
  const warningNextBillLastDay = (deadline) => {
    const header = 'Última chamada! 🚀'
    const colorWarning = theme.palette.hub.lightGreen
    const displayClock = false
    const bills = ' Pagar agora'
    const displaySeconds = false
    const onClick = () => {
      analytics.logEvent(STUDENT_HUB_EVENTS.banner.btn.openGetnet)
      window.open(nextBill.credit_card, '_blank')
    }
    const message = `Aqui vai um lembrete de que sua mensalidade vence hoje. 
      Afinal, é sempre melhor evitar juros e multa, não é mesmo? 
      Conte com a gente para qualquer suporte necessário! 😄`

    const footer = (
      <Button
        onClick={onClick}
        sx={{
          color: colorWarning,
          ...buttonStyle,
          '&.MuiButton-root:hover': { bgcolor: 'white' }
        }}
      >
        {' '}
        {bills}
      </Button>
    )
    return (
      <WarningCountDown
        backgroundColor={colorWarning}
        header={header}
        deadline={deadline}
        displayClock={displayClock}
        message={message}
        footer={footer}
        displaySeconds={displaySeconds}
        height="150px"
      />
    )
  }

  const warningOverdue = (deadline) => {
    const header = 'Mensalidade vencida? Vamos resolver!'
    const colorWarning = theme.palette.hub.orange
    const displayClock = false
    const bills = 'Clique aqui para encontrarmos a melhor solução.'
    const displaySeconds = false
    const onClick = () => {
      analytics.logEvent(STUDENT_HUB_EVENTS.banner.btn.openAtendimento)
      callHiBot(userStore.name, userStore.telephone, userStore.cpf)
    }
    const message =
      'Notamos que a sua mensalidade está em atraso e queremos te ajudar a regularizar'

    const footer = (
      <Button
        onClick={onClick}
        sx={{
          color: colorWarning,
          ...buttonStyle,
          '&.MuiButton-root:hover': { bgcolor: 'white' }
        }}
      >
        {' '}
        {bills}
      </Button>
    )
    return (
      <WarningCountDown
        backgroundColor={colorWarning}
        header={header}
        deadline={deadline}
        displayClock={displayClock}
        message={message}
        footer={footer}
        displaySeconds={displaySeconds}
        height="150px"
      />
    )
  }

  const warningNextBill = () => {
    const deadline = userStore?.re_enroll_end_date

    if (nextBill) {
      const differenceInDays =
        Math.floor(
          (new Date(nextBill.due_date) - new Date()) /
            millisecondDayConversionFactor
        ) + 1

      if (differenceInDays === 0) return warningNextBillLastDay(deadline)
      if (differenceInDays <= 5 && differenceInDays > 0) {
        return warningNextBillFiveToOne(deadline, differenceInDays)
      }
    }

    return studentOverdue.has_overdue ? warningOverdue(deadline) : null
  }

  const warningReenrolmentBlue = () => {
    const header = 'O período de rematrícula começa em:'
    const colorWarning = theme.palette.hub.blue
    const displayClock = true
    const deadline = userStore.re_enroll_start_date
    const bills = 'Falar com atendimento'
    const displaySeconds = false
    const onClick = () => {
      analytics.logEvent(STUDENT_HUB_EVENTS.banner.btn.openAtendimento)
      callHiBot(userStore.name, userStore.telephone, userStore.cpf)
    }
    const message =
      'Entre em contato com o Atendimento para regularizar sua situação financeira e poder realizar sua rematrícula.'
    const footer = (
      <Button
        onClick={onClick}
        sx={{
          color: colorWarning,
          ...buttonStyle,
          '&.MuiButton-root:hover': { bgcolor: 'white' }
        }}
      >
        {bills}
      </Button>
    )

    return (
      <WarningCountDown
        backgroundColor={colorWarning}
        header={header}
        deadline={deadline}
        displayClock={displayClock}
        message={message}
        footer={footer}
        displaySeconds={displaySeconds}
        height="150px"
      />
    )
  }

  const warningReenrolmentOrange = () => {
    const bills = 'Falar com atendimento'
    const header = 'O período de rematrícula acaba em:'
    const colorWarning = theme.palette.hub.orange
    const displayClock = true
    const displaySeconds = true
    const deadline = userStore.re_enroll_end_date
    const onClick = () => {
      analytics.logEvent(STUDENT_HUB_EVENTS.banner.btn.openAtendimento)
      callHiBot(userStore.name, userStore.telephone, userStore.cpf)
    }
    const message =
      'Entre em contato com o Atendimento para regularizar sua situação financeira e confirmar sua rematrícula.'
    const footer = (
      <Button
        onClick={onClick}
        sx={{
          color: colorWarning,
          ...buttonStyle,
          '&.MuiButton-root:hover': { bgcolor: 'white' }
        }}
      >
        {bills}
      </Button>
    )

    return (
      <WarningCountDown
        backgroundColor={colorWarning}
        header={header}
        deadline={deadline}
        displayClock={displayClock}
        message={message}
        footer={footer}
        displaySeconds={displaySeconds}
        height="150px"
      />
    )
  }

  const warningReenrolmentRed = () => {
    const deadline = userStore.re_enroll_end_date
    const displaySeconds = false
    const bills = 'Falar com atendimento'
    const message =
      'Entre em contato com o Atendimento para regularizar sua situação financeira e poder realizar sua rematrícula.'
    const header = `Rematrícula encerrada em ${formatDate(
      userStore?.re_enroll_end_date
    )}`
    const colorWarning = theme.palette.hub.red
    const displayClock = false
    const onClick = () => {
      analytics.logEvent(STUDENT_HUB_EVENTS.banner.btn.openAtendimento)
      callHiBot(userStore.name, userStore.telephone, userStore.cpf)
    }

    const footer = (
      <Button
        onClick={onClick}
        sx={{
          color: colorWarning,
          ...buttonStyle,
          '&.MuiButton-root:hover': { bgcolor: 'white' }
        }}
      >
        {bills}
      </Button>
    )

    return (
      <WarningCountDown
        backgroundColor={colorWarning}
        header={header}
        deadline={deadline}
        displayClock={displayClock}
        message={message}
        footer={footer}
        displaySeconds={displaySeconds}
        height="150px"
      />
    )
  }

  const configWarning = () => {
    const deadlineStart = new Date(userStore.re_enroll_start_date)
    const deadlineEnd = new Date(userStore.re_enroll_end_date)

    if (!userStore?.re_enroll_end_date) {
      return warningNextBill()
    }
    if (deadlineStart.getTime() > today.getTime() && studentOverdue.has_overdue)
      return warningReenrolmentBlue()

    if (
      deadlineStart.getTime() <= today.getTime() &&
      deadlineEnd.getTime() > today.getTime() &&
      studentOverdue.has_overdue
    )
      return warningReenrolmentOrange()

    if (deadlineEnd.getTime() <= today.getTime()) return warningReenrolmentRed()

    if (!userStore?.re_enroll_end_date) {
      return warningNextBill()
    }

    return null
  }

  const handleStudentHasOverdue = async ({ register }) => {
    const {
      data: overdue,
      error,
      status
    } = await getStudentHasOverdue({ register })
    if (error) {
      updateErrorMessage({
        error,
        status
      })
    }

    if (overdue) {
      setStudentOverdue(overdue)
      if (overdue.has_overdue) setOpenModalStudentOverdue(true)
    }
  }

  useEffect(async () => {
    setLoading(true)
    await handleStudentHasOverdue({ register: id })
    const nextBills = await getFinancialNextBill(id)
    if (nextBills.data.count > 0) setNextBill(nextBills.data.data[0])

    if (userStore.has_pending_re_enroll) {
      const reEnrollmentInfo = await reEnrollmentList({ username: id })

      if (
        Object.keys(reEnrollmentInfo).length &&
        !reEnrollmentInfo?.reenrollment_deadline_expired &&
        !reEnrollmentInfo?.pending_financial_situation
      ) {
        await handleAcceptTerm({
          isAutomatic: true,
          studentReenrollmentId: reEnrollmentInfo.student_reenrollment_id
        })
      }
    }
    setLoading(false)
  }, [])
  return (
    <>
      <Styled.Container>
        <Styled.Header />
        <Styled.Body>
          <Styled.HeaderCircle>
            {initials[0]}
            {initials[1]}
          </Styled.HeaderCircle>
          <Styled.Greeting>Olá, {firstName}</Styled.Greeting>
          <Grid container spacing={2}>
            {displayGradesAndFinance && (
              <Grid xs={12}>
                <ButtonFlat
                  orientation="horizontal"
                  backgroundColor={theme.palette.hub.purple}
                  label="Atendimento"
                  icon={MessageIcon}
                  fontColor="white"
                  onClick={() => {
                    callHiBot(
                      userStore.name,
                      userStore.telephone,
                      userStore.cpf
                    )
                  }}
                />
              </Grid>
            )}
            <Grid xs={12}>{configWarning(location.state?.term)}</Grid>
            <Grid xs={4} md={6}>
              <ButtonFlat
                orientation="vertical"
                backgroundColor={theme.palette.hub.lightPurple}
                label="EAD"
                icon={ComputerIcon}
                fontColor="white"
                onClick={() => {
                  analytics.logEvent(STUDENT_HUB_EVENTS.btn.openEAD)
                  navigate('../JoyClass')
                }}
                height="68px"
              />
            </Grid>
            {displayGradesAndFinance && (
              <Grid xs={4} md={6}>
                <ButtonFlat
                  orientation="vertical"
                  backgroundColor={theme.palette.hub.darkBlue}
                  label="Notas e faltas"
                  icon={SpellcheckIcon}
                  fontColor="white"
                  onClick={() => {
                    analytics.logEvent(STUDENT_HUB_EVENTS.btn.openNotasEFaltas)
                    navigate(STUDENT_ROUTE.STUDENT_MY_SCORES)
                  }}
                  height="68px"
                />
              </Grid>
            )}
            {displayGradesAndFinance && (
              <Grid xs={4} md={6}>
                <ButtonFlat
                  orientation="vertical"
                  backgroundColor={theme.palette.hub.green}
                  label="Financeiro"
                  icon={PaidIcon}
                  fontColor="white"
                  onClick={() => {
                    analytics.logEvent(STUDENT_HUB_EVENTS.btn.openFinanceiro)
                    navigate(STUDENT_ROUTE.STUDENT_FINANCIAL)
                  }}
                  height="68px"
                />
              </Grid>
            )}
            <Grid xs={4} md={6}>
              <ButtonFlat
                orientation="vertical"
                backgroundColor={theme.palette.hub.orange}
                label="Unidade"
                icon={InfoIcon}
                fontColor="white"
                onClick={() => {
                  analytics.logEvent(STUDENT_HUB_EVENTS.btn.openUnidade)
                  navigate(STUDENT_ROUTE.STUDENT_UNIT_INFO)
                }}
                height="68px"
              />
            </Grid>
            <Grid container xs={12}>
              <Grid xs={6}>
                <Styled.SocialMediaContainer>
                  <Styled.SocialMediaIcon
                    onClick={() => {
                      analytics.logEvent(STUDENT_HUB_EVENTS.btn.openInstagram)
                      window.open(
                        'https://www.instagram.com/prozeducacaobr/',
                        '_blank'
                      )
                    }}
                  >
                    <InstagramIcon />
                  </Styled.SocialMediaIcon>
                </Styled.SocialMediaContainer>
              </Grid>
            </Grid>
          </Grid>
        </Styled.Body>
      </Styled.Container>
    </>
  )
}

export default StudentHub
